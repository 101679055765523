export enum ProductType {
    Extension = "Extension",
    Theme = "Theme",
    MicrosoftCopilotPlugin = "MicrosoftCopilotPlugin"
}

export enum ActionType {
    View = "View",
    Search = "Search",
    Edit = "Edit",
    Create = "Create",
    Initiate = "Initiate",
    Status = "Status"
}

export enum ResourceType {
    ThreatAnalysisRecord = "ThreatAnalysisRecord",
    Information = "Information",
    ScanReport = "ScanReport",
    Rules = "Rules",
    ScanInformation = "ScanInformation",
    Scan = "Scan",
    Search = "Search",
    Home = "Home"
  }
  
  export enum IdentifierType {
    SubmissionId = "SubmissionId",
    CrxId = "CrxId"
  }
  
export enum SourceOfReportPrePublish {
    SourceOfReportContainsObfuscatedCode = "Contains obfuscated code",
    SourceOfReportDarsyTechnicalQueue = "Darsy-Technical queue",
    SourceOfReportDarsyTechnicalOthers = "Darsy-Technical others",
    SourceOfReportFlaggedByStaticAnalysis = "Flagged by static analysis",
    SourceOfReportStoreOpsRequest = "Requested by store ops",
    SourceOfReportDarsyTechnicalQueueSA = "Darsy-TechnicalQueue - SA",
    SourceOfReportDarsyTechnicalQueueYARA = "Darsy-TechnicalQueue - YARA",
    SourceOfReportDarsyTechnicalQueueCodeQL = "Darsy-TechnicalQueue - CodeQL",
    SourceOfReportFlaggedByCodeQL = "Flagged by CodeQL",
    SourceOfReportFlaggedByHashScanner = "Flagged by Hash Scanner",
    SourceOfReportFlaggedByYARA = "Flagged by YARA",
    SourceOfReportDarsyTechnicalQueueStoreOps = "Darsy-TechnicalQueue - StoreOps",
    SourceOfReportDarsyTechnicalQueueFAA = "Darsy-TechnicalQueue - Flagged by accuracy analysis",
    SourceOfReportPreFlaggedByAccuracyAnalysis = "Flagged by accuracy analysis",
    SourceOfReportPreFlaggedByURLReputationService = "Flagged by URL Reputation Service",
    SourceOfReportDarsyTechnicalQueueURLReputationService = "Darsy-TechnicalQueue - URL Reputation Service",
    SourceOfReportPreSuspiciousDeveloper = "Suspicious developer",
    SourceOfReportPreDuplicateDetection = "Duplicate detection",
    SourceOfReportDarsyTechnicalQueueSuspiciousDeveloper = "Darsy-TechnicalQueue - Suspicious developer",
    SourceOfReportDarsyTechnicalQueueDuplicateDetection = "Darsy-TechnicalQueue - Duplicate detection",
    SourceOfReportDarsyTechnicalQueueDA = "Darsy-TechnicalQueue - Dynamic Analysis",
    SourceOfReportPreFlaggedByDA = "Flagged by Dynamic Analysis",
    SourceOfReportDarsyTechnicalQueueURLDetonation = "Darsy-TechnicalQueue - URL Detonation",
    SourceOfReportPreURLDetonation = "URL Detonation",
    SourceOfReportPreFlaggedbyPRSSYara = "Flagged by PRSS - Yara",
    SourceOfReportPreFlaggedbyObfuscationService = "Flagged by Obfuscation Service",
    SourceOfReportPreThreatHunting = "Threat Hunting",
}

export enum SourceOfReportPostPublish {
    SourceOfReportAppDefaultsDSEDNR = "AppDefaults DSE DNR",
    SourceOfReportAppDefaultsDSEWebrequest = "AppDefaults DSE Webrequest",
    SourceOfReportAppDefaultsTabsCreate = "AppDefaults Tabs Create",
    SourceOfReportAppDefaultsTabsRemove = "AppDefaults Tabs Remove",
    SourceOfReportAppDefaultsTabsUpdate = "AppDefaults Tabs Update",
    SourceOfReportAvertServiceViolation = "Avert service violation",
    SourceOfReportBingAdsHijack = "Bing Ads hijack",
    SourceOfReportBingAffiliation = "Bing Affiliation",
    SourceOfReportBingAutosearch = "Bing Autosearch",
    SourceOfReportBingDSQDetections = "BingDSQ Detections",
    SourceOfReportBingHijackMonitoring = "Bing Hijack Monitoring",
    SourceOfReportCassandraThreatIntel = "Cassandra Threat Intel",
    SourceOfReportChromeRemoved = "Chrome Removed",
    SourceOfReportClientTelemetry = "Client telemetry",
    SourceOfReportExtensionPolicyDownload ="Extension Policy Download",
    SourceOfReportExternalReport ="External Report",
    SourceOfReportFlaggedbyETFCookiesGet = "Flagged by ETF - Cookies Get",
    SourceOfReportFlaggedbyETFCookiesGetAll = "Flagged by ETF - Cookies GetAll",
    SourceOfReportFlaggedbyETFRemoteHost = "Flagged by ETF - Remote Host",
    SourceOfReportFlaggedbyETFTabsExecutescript = "Flagged by ETF - Tabs Executescript",
    SourceOfReportFlaggedbyETFTelemetry = "Flagged by ETF Telemetry",
    SourceOfReportFlaggedbyLogAnalyticsQuery = "Flagged by Log Analytics Query",
    SourceOfReportFlaggedByHashScanner = "Flagged by Hash Scanner",
    SourceOfReportFlaggedbyRescan = "Flagged by Rescan",
    SourceOfReportFlaggedbyUKMTelemetry = "Flagged by UKM Telemetry",
    SourceOfReportICMReportAbuse = "ICM - Report abuse",
    SourceOfReportInactiveInChrome = "Inactive in Chrome/Active in Edge",
    SourceOfReportMaliciousSignatureCodeQL = "Malicious signature by CodeQL",
    SourceOfReportMaliciousSignatureDefender = "Malicious code signatures through defender",
    SourceOfReportMaliciousSignatureWithYARA = "Malicious signature with YARA",
    SourceOfReportMaliciousSignatureWithYARAActive = "Malicious signature with YARA - Active",
    SourceOfReportMaliciousSignatureWithYARATakedown = "Malicious signature with YARA - Takedown",
    SourceOfReportMaliciousSignatureWithYARAUnpublished = "Malicious signature with YARA - Unpublished",
    SourceOfReportMalwareReported = "Virus/Spyware/Malware - Report Abuse",
    SourceOfReportMediaReported = "Reported in Media/Public forums",
    SourceOfReportOtherReported = "Other - Report Abuse",
    SourceOfReportOthers = "Others",
    SourceOfReportPostDuplicateDetection = "Duplicate detection",
    SourceOfReportPostFlaggedByAccuracyAnalysis = "Flagged by accuracy analysis",
    SourceOfReportPostFlaggedByDA = "Flagged by Dynamic Analysis",
    SourceOfReportPostFlaggedbyObfuscationService = "Flagged by Obfuscation Service",
    SourceOfReportPostFlaggedbyPRSSYara = "Flagged by PRSS - Yara",
    SourceOfReportPostFlaggedByURLReputationService = "Flagged by URL Reputation Service",
    SourceOfReportPostSuspiciousDeveloper = "Suspicious developer",
    SourceOfReportPostThreatHuntingFlaggedInMediaBlog = "Threat Hunting - Flagged in Media blog",
    SourceOfReportPostURLDetonation = "URL Detonation",
    SourceOfReportSuspiciousReferrerURL = "Suspicious referrer URL",
    SourceOfReportPostThreatHuntingCategoryHunting = "Threat Hunting - Category Hunting",
    SourceOfReportPostThreatHuntingFlaggedInVTCrxADOs = "Threat Hunting - Flagged in VT Crx ADOs",
    SourceOfReportPostThreatHuntingNewExperiment = "Threat Hunting - New Experiment",
    SourceOfReportPostThreatHuntingOthers = "Threat Hunting - Others",
    SourceOfReportPostThreatHuntingProactive = "Threat Hunting - Proactive",
    SourceOfReportPostThreatHuntingRecentlyRevokedChrome = "Threat Hunting - Recently revoked chrome",
    SourceOfReportSearchHijackTabsUpdate = "Search Hijack Tabs Update",
    SourceOfReportSearchHijackTabsRemove = "Search Hijack Tabs Remove",
    SourceOfReportSideLoadedViaGP = "Side Loaded Via GP",
    SourceOfReportUkmTabsCombination = "Ukm Tabs Combination",
    SourceOfReportUnpublishedExtension = "Unpublished Extension",
}

export enum PublishType {
    PrePublish = "Pre Publish",
    PostPublish = "Post Publish",
}
export enum AnalysisRecommendation {
    Fail = "Fail",
    Pass = "Pass",
    PassWithNotes = "Pass With Notes",
    Revoke = "Revoke",
    Takedown = "Takedown",
    Hold = "Hold",
    RevokePendingReview = "Revoke-Pending Review"
}

export enum AttackVectorCategory {
    Adware = "Adware",
    Affiliation = "Affiliation",
    BlacklistedUrl = "Blacklisted Url",
    CryptoMining = "Crypto Mining",
    DDos = "DDoS",
    IframeInjection = "Iframe Injection",
    InstallingUnwantedSoftware = "Installing Unwanted Software",
    KeyboardTracking = "Keyboard Tracking",
    None = "None",
    NTPHijack = "NTP Hijack",
    ObfuscatedCode = "Obfuscated Code",
    Other = "Other",
    PasswordSniffing = "Password Sniffing",
    Phishing = "Phishing",
    RemoteScriptInjection = "Remote Script Injection",
    SearchHijack = "Search Hijack",
    SocialMediaHijacking = "Social Media Hijacking",
    TrojanImposter = "Trojan or Imposter",
    Trojan = "Trojan",
    Imposter = "Imposter",
    UserDataBreach = "User Data Breach",
    XSS = "XSS",
}

export enum MITREAttack {
    BrowserExtensions = "Browser Extensions",
}

export enum DeveloperAccountType {
    Business = "Business",
    Individual = "Individual",
}

export enum ReportState {
    Draft = "Draft",
    Published = "Published",
}

export enum IOCType {
    Url = "URL",
    IP = "IP",
    FileHash = "File Hash",
}

export enum ScanType {
    PrePublishScanType = "PrepublishStaticAnalysis",
    PostPublishRescanType = "PostPublishRescan",
}

export enum ScanStatus {
    Initiated = 0,
    Completed = 1,
    Failed = 2,
    Inprogress = 3,
    Skipped = 4,
    Canceled = 5,
}

export enum Keys {
    AdwareAttackVectorCategory = "AdwareAttackVectorCategory",
    AffiliationAttackVectorCategory = "AffiliationAttackVectorCategory",
    BlacklistedUrlAttackVectorCategory = "BlacklistedUrlAttackVectorCategory",
    CryptoMiningAttackVectorCategory = "CryptoMiningAttackVectorCategory",
    DDosAttackVectorCategory = "DDosAttackVectorCategory",
    DeveloperAccountTypeBusiness = "DeveloperAccountTypeBusiness",
    DeveloperAccountTypeIndividual = "DeveloperAccountTypeIndividual",
    FailAnalysisRecommendation = "FailAnalysisRecommendation",
    HoldAnalysisRecommendation = "HoldAnalysisRecommendation",
    IframeInjectionAttackVectorCategory = "IframeInjectionAttackVectorCategory",
    InstallingUnwantedSoftwareAttackVectorCategory = "InstallingUnwantedSoftwareAttackVectorCategory",
    KeyboardTrackingAttackVectorCategory = "KeyboardTrackingAttackVectorCategory",
    MaliciousNoDropDownKey = "MaliciousNoDropDownKey",
    MaliciousYesDropDownKey = "MaliciousYesDropDownKey",
    MaliciousUnknownDropDownKey = "MaliciousUnknownDropDownKey",
    Name = "Name",
    NTPHijackAttackVectorCategory = "NTPHijackAttackVectorCategory",
    NoneAttackVectorCategory = "NoneAttackVectorCategory",
    ObfuscatedCodeAttackVectorCategory = "ObfuscatedCodeAttackVectorCategory",
    ObfuscatedYesDropDownKey = "ObfuscatedYesDropDownKey",
    ObfuscatedNoDropDownKey = "ObfuscatedNoDropDownKey",
    ObfuscatedUnknownDropDownKey = "ObfuscatedUnknownDropDownKey",
    OtherAttackVectorCategory = "OtherAttackVectorCategory",
    PassAnalysisRecommendation = "PassAnalysisRecommendation",
    PassWithNotesAnalysisRecommendation = "PassWithNotesAnalysisRecommendation",
    PasswordSniffingAttackVectorCategory = "PasswordSniffingAttackVectorCategory",
    PhishingAttackVectorCategory = "PhishingAttackVectorCategory",
    RemoteScriptInjectionAttackVectorCategory = "RemoteScriptInjectionAttackVectorCategory",
    PostPublishDropDownKey = "PostPublish",
    PrePublishDropDownKey = "PrePublish",
    ProductTypeExtension = "ProductTypeExtension",
    ProductTypeTheme = "ProductTypeTheme",
    ReproducibleNoDropDownKey = "ReproducibleNoDropDownKey",
    ReproducibleYesDropDownKey = "ReproducibleYesDropDownKey",
    RevokeAnalysisRecommendation = "RevokeAnalysisRecommendation",
    RevokePendingReviewAnalysisRecommendation = "RevokePendingReviewAnalysisRecommendation",
    Role = "Role",
    SearchHijackAttackVectorCategory = "SearchHijackAttackVectorCategory",
    SignOut = "SignOut",
    SocialMediaHijackingAttackVectorCategory = "SocialMediaHijackingAttackVectorCategory",
    SourceOfReportAvertServiceViolation = "SourceOfReportAvertServiceViolation",
    SourceOfReportPreDuplicateDetection = "SourceOfReportPreDuplicateDetection",
    SourceOfReportPostDuplicateDetection = "SourceOfReportPostDuplicateDetection",
    SourceOfReportExtensionPolicyDownload = "SourceOfReportExtensionPolicyDownload",
    SourceOfReportExternalReport = "SourceOfReportExternalReport",
    SourceOfReportSearchHijackTabsUpdate = "SourceOfReportSearchHijackTabsUpdate",
    SourceOfReportSearchHijackTabsRemove = "SourceOfReportSearchHijackTabsRemove",
    SourceOfReportSideLoadedViaGP = "SourceOfReportSideLoadedViaGP",
    SourceOfReportUkmTabsCombination = "SourceOfReportUkmTabsCombination",
    SourceOfReportFlaggedByStaticAnalysis = "SourceOfReportFlaggedByStaticAnalysis",
    SourceOfReportFlaggedByHashScanner = "SourceOfReportFlaggedByHashScanner",
    SourceOfReportPreFlaggedByAccuracyAnalysis = "SourceOfReportPreFlaggedByAccuracyAnalysis",
    SourceOfReportPostFlaggedByAccuracyAnalysis = "SourceOfReportPostFlaggedByAccuracyAnalysis",
    SourceOfReportDarsyTechnicalQueue = "SourceOfReportDarsyTechnicalQueue",
    SourceOfReportDarsyTechnicalOthers = "SourceOfReportDarsyTechnicalOthers",
    SourceOfReportContainsObfuscatedCode = "SourceOfReportContainsObfuscatedCode",
    SourceOfReportStoreOpsRequest = "SourceOfReportStoreOpsRequest",
    SourceOfReportPreSuspiciousDeveloper = "SourceOfReportPreSuspiciousDeveloper",
    SourceOfReportPostSuspiciousDeveloper = "SourceOfReportPostSuspiciousDeveloper",
    SourceOfReportInactiveInChrome = "SourceOfReportInactiveInChrome",
    SourceOfReportICMReportAbuse = "SourceOfReportICMReportAbuse",
    SourceOfReportMalwareReported = "SourceOfReportMalwareReported",
    SourceOfReportMediaReported = "SourceOfReportMediaReported",
    SourceOfReportOthers = "SourceOfReportOthers",
    SourceOfReportOtherReported = "SourceOfReportOtherReported",
    SourceOfReportSuspiciousReferrerURL = "SourceOfReportSuspiciousReferrerURL",
    SourceOfReportMaliciousSignatureCodeQL = "SourceOfReportMaliciousSignatureCodeQL",
    SourceOfReportMaliciousSignatureWithYARA = "SourceOfReportMaliciousSignatureWithYARA",
    SourceOfReportMaliciousSignatureWithYARAActive = "SourceOfReportMaliciousSignatureWithYARAActive",
    SourceOfReportMaliciousSignatureWithYARAUnpublished = "SourceOfReportMaliciousSignatureWithYARAUnpublished",
    SourceOfReportMaliciousSignatureWithYARATakedown = "SourceOfReportMaliciousSignatureWithYARATakedown",
    SourceOfReportClientTelemetry = "SourceOfReportClientTelemetry",
    SourceOfReportMaliciousSignatureDefender = "SourceOfReportMaliciousSignatureDefender",
    SourceOfReportDarsyTechnicalQueueSA = "SourceOfReportDarsyTechnicalQueueSA",
    SourceOfReportDarsyTechnicalQueueYARA = "SourceOfReportDarsyTechnicalQueueYARA",
    SourceOfReportDarsyTechnicalQueueCodeQL = "SourceOfReportDarsyTechnicalQueueCodeQL",
    SourceOfReportFlaggedByCodeQL = "SourceOfReportFlaggedByCodeQL",
    SourceOfReportFlaggedByYARA = "SourceOfReportFlaggedByYARA",
    SourceOfReportDarsyTechnicalQueueStoreOps = "SourceOfReportDarsyTechnicalQueueStoreOps",
    SourceOfReportDarsyTechnicalQueueFAA = "SourceOfReportDarsyTechnicalQueueFAA",
    SourceOfReportDarsyTechnicalQueueURLReputationService = "SourceOfReportDarsyTechnicalQueueURLReputationService",
    SourceOfReportPreFlaggedByURLReputationService = "SourceOfReportPreFlaggedByURLReputationService",
    SourceOfReportPostFlaggedByURLReputationService = "SourceOfReportPostFlaggedByURLReputationService",
    SourceOfReportDarsyTechnicalQueueSuspiciousDeveloper = "SourceOfReportDarsyTechnicalQueueSuspiciousDeveloper",
    SourceOfReportDarsyTechnicalQueueDuplicateDetection = "SourceOfReportDarsyTechnicalQueueDuplicateDetection",
    SourceOfReportDarsyTechnicalQueueDA = "SourceOfReportDarsyTechnicalQueueDA",
    SourceOfReportPostFlaggedByDA = "SourceOfReportPostFlaggedByDA",
    SourceOfReportPreFlaggedByDA = "SourceOfReportPreFlaggedByDA",
    SourceOfReportDarsyTechnicalQueueURLDetonation = "SourceOfReportDarsyTechnicalQueueURLDetonation",
    SourceOfReportPreURLDetonation = "SourceOfReportPreURLDetonation",
    SourceOfReportPostURLDetonation = "SourceOfReportPostURLDetonation",
    SourceOfReportFlaggedbyUKMTelemetry = "SourceOfReportFlaggedbyUKMTelemetry",
    SourceOfReportFlaggedbyETFTelemetry = "SourceOfReportFlaggedbyETFTelemetry",
    SourceOfReportFlaggedbyETFRemoteHost = "SourceOfReportFlaggedbyETFRemoteHost",
    SourceOfReportFlaggedbyETFCookiesGetAll = "SourceOfReportFlaggedbyETFCookiesGetAll",
    SourceOfReportFlaggedbyETFCookiesGet = "SourceOfReportFlaggedbyETFCookiesGet",
    SourceOfReportFlaggedbyETFTabsExecutescript = "SourceOfReportFlaggedbyETFTabsExecutescript",
    SourceOfReportFlaggedbyLogAnalyticsQuery = "SourceOfReportFlaggedbyLogAnalyticsQuery",
    SourceOfReportPreFlaggedbyObfuscationService = "SourceOfReportPreFlaggedbyObfuscationService",
    SourceOfReportPostFlaggedbyObfuscationService = "SourceOfReportPostFlaggedbyObfuscationService",
    SourceOfReportFlaggedbyRescan = "SourceOfReportFlaggedbyRescan",
    SourceOfReportPreFlaggedbyPRSSYara = "SourceOfReportPreFlaggedbyPRSSYara",
    SourceOfReportPostFlaggedbyPRSSYara = "SourceOfReportPostFlaggedbyPRSSYara",
    SourceOfReportBingHijackMonitoring = "SourceOfReportBingHijackMonitoring",
    SourceOfReportBingDSQDetections = "SourceOfReportBingDSQDetections",
    SourceOfReportCassandraThreatIntel = "SourceOfReportCassandraThreatIntel",
    SourceOfReportAppDefaultsDSEWebrequest = "SourceOfReportAppDefaultsDSEWebrequest",
    SourceOfReportAppDefaultsDSEDNR = "SourceOfReportAppDefaultsDSEDNR",
    SourceOfReportAppDefaultsTabsRemove = "SourceOfReportAppDefaultsTabsRemove",
    SourceOfReportAppDefaultsTabsUpdate = "SourceOfReportAppDefaultsTabsUpdate",
    SourceOfReportAppDefaultsTabsCreate = "SourceOfReportAppDefaultsTabsCreate",
    SourceOfReportUnpublishedExtension = "SourceOfReportUnpublishedExtension",
    SourceOfReportPostThreatHuntingFlaggedInMediaBlog = "SourceOfReportPostThreatHuntingFlaggedInMediaBlog",
    SourceOfReportPostThreatHuntingCategoryHunting = "SourceOfReportPostThreatHuntingCategoryHunting",
    SourceOfReportPostThreatHuntingFlaggedInVTCrxADOs = "SourceOfReportPostThreatHuntingFlaggedInVTCrxADOs",
    SourceOfReportPostThreatHuntingNewExperiment = "SourceOfReportPostThreatHuntingNewExperiment",
    SourceOfReportPostThreatHuntingOthers = "SourceOfReportPostThreatHuntingOthers",
    SourceOfReportPostThreatHuntingProactive = "SourceOfReportPostThreatHuntingProactive",
    SourceOfReportPostThreatHuntingRecentlyRevokedChrome = "SourceOfReportPostThreatHuntingRecentlyRevokedChrome",
    SourceOfReportPreThreatHunting = "SourceOfReportPreThreatHunting",
    SourceOfReportBingAffiliation = "SourceOfReportBingAffiliation",
    SourceOfReportBingAutosearch = "SourceOfReportBingAutosearch",
    SourceOfReportBingAdsHijack = "SourceOfReportBingAdsHijack",
    SourceOfReportChromeRemoved = "SourceOfReportChromeRemoved",
    SubmitNewSummary = "SubmitNewSummary",
    TakedownAnalysisRecommendation = "TakedownAnalysisRecommendation",
    TrojanImposterAttackVectorCategory = "TrojanImposterAttackVectorCategory",
    TrojanAttackVectorCategory = "TrojanAttackVectorCategory",
    ImposterAttackVectorCategory = "ImposterAttackVectorCategory",
    UserDataBreachAttackVectorCategory = "UserDataBreachAttackVectorCategory",
    XSSAttackVectorCategory = "XSSAttackVectorCategory",
    /* max item combo box keys */
    Max50ItemsPerPage = "Max50ItemsPerPage",
    Max500ItemsPerPage = "Max500ItemsPerPage",
    Max1000ItemsPerPage = "Max1000ItemsPerPage",
    Max2000ItemsPerPage = "Max2000ItemsPerPage",
    Max5000ItemsPerPage = "Max5000ItemsPerPage",
    Max10000ItemsPerPage = "Max10000ItemsPerPage",
    Max20000ItemsPerPage = "Max20000ItemsPerPage",
    /* Select Id to search by */
    SearchByBigId = "SearchByBigId",
    SearchByCrxId = "SearchByCrxId",
    SearchBySubmissionId = "SearchBySubmissionId",
    /*Search filter keys*/
    PublishClassification = "PublishClassification",
    MaliciousClassification = "MaliciousClassification",
    PrePublishReportSource = "PrePublishReportSource",
    PostPublishReportSource = "PostPublishReportSource",
    ChromeStoreURLPresent = "ChromeStoreURLPresent",
    ChromeStoreURLAbsent = "ChromeStoreURLAbsent",
    DaasURLPresent = "DaasURLPresent",
    DaasURLAbsent = "DaasURLAbsent",
    IOCURL = "IOCURL",
    IOCIP = "IOCIP",
    IOCFileHash = "IOCFileHash",
    PrePublishScanType = "PrepublishStaticAnalysis",
    PostPublishRescanType = "PostPublishRescanType",
    DefaultRuleId = "ad_allblock_codeql",
    NoneRuleId = "NoneRuleId",
}

export enum ScannerKeys {
    AggregateReport = "AggregateReport",
    StaticAnalysisCodeQLScan = "StaticAnalysisCodeQLScan",
    DynamicAnalysisNetworkLogScan = "DynamicAnalysisNetworkLogScan",
    DynamicAnalysisActivityLogScan = "DynamicAnalysisActivityLogScan",
    StaticAnalysisTokenScan = "StaticAnalysisTokenScan",
    StaticAnalysisManifestScan = "StaticAnalysisManifestScan",
    StaticAnalysisDeveloperScan = "StaticAnalysisDeveloperScan",
    StaticAnalysisMetadataScan = "StaticAnalysisMetadataScan",
    StaticAnalysisDuplicateDetectionScan = "StaticAnalysisDuplicateDetectionScan",
    StaticAnalysisObfuscationScan = "StaticAnalysisObfuscationScan",
    HashScan = "HashScan",
    EvalScan = "EVALScan",
    EvalRAIScan = "EvalRAIScan",
    BingChatPlugin = "BingChatPlugin",
}

export enum RuleTypes {
    malicious_url_found_in_manifest = "malicious_url_found_in_manifest",
    malicious_url_found_in_metadata = "malicious_url_found_in_metadata",
    malicious_url_found_in_js_or_html_files = "malicious_url_found_in_js_or_html_files",
    malicious_url_found_in_developer_info = "malicious_url_found_in_developer_info",
}

export enum Publishers {
    PrepublishStaticAnalysis = 0,
    PrepublishDynamicAnalysis = 1,
    CoreDynamicAnalysis = 2,
    PostPublishRescan = 3,
    OnDemandScan = 4
}

export enum Environments {
    Test = "test",
    Development = "development",
    Production = "production"
}