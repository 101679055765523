import { DropdownMenuItemType, IChoiceGroupOption, IComboBoxOption, IDropdownOption } from "@fluentui/react";
import localeStrings from "../../../assets/localeStrings/english.json";
import {
    AnalysisRecommendation,
    AttackVectorCategory,
    DeveloperAccountType,
    IOCType,
    ProductType,
    PublishType,
    ScanType,
    SourceOfReportPostPublish,
    SourceOfReportPrePublish,
    Keys,
    ScannerKeys
} from "../../lib/enums/common-enums";

export const PublishDropdownOptions: IDropdownOption[] = [
    { key: Keys.PrePublishDropDownKey, text: PublishType.PrePublish },
    { key: Keys.PostPublishDropDownKey, text: PublishType.PostPublish },
];

export const SourceOfReportPrePublishDropdownOptions: IDropdownOption[] = [
    { key: Keys.SourceOfReportFlaggedByStaticAnalysis, text: SourceOfReportPrePublish.SourceOfReportFlaggedByStaticAnalysis },
    { key: Keys.SourceOfReportContainsObfuscatedCode, text: SourceOfReportPrePublish.SourceOfReportContainsObfuscatedCode },
    { key: Keys.SourceOfReportStoreOpsRequest, text: SourceOfReportPrePublish.SourceOfReportStoreOpsRequest },
    { key: Keys.SourceOfReportDarsyTechnicalQueue, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueue },
    { key: Keys.SourceOfReportDarsyTechnicalOthers, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalOthers },
    { key: Keys.SourceOfReportDarsyTechnicalQueueSA, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueSA },
    { key: Keys.SourceOfReportDarsyTechnicalQueueYARA, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueYARA },
    { key: Keys.SourceOfReportDarsyTechnicalQueueCodeQL, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueCodeQL },
    { key: Keys.SourceOfReportFlaggedByCodeQL, text: SourceOfReportPrePublish.SourceOfReportFlaggedByCodeQL },
    { key: Keys.SourceOfReportFlaggedByHashScanner, text: SourceOfReportPrePublish.SourceOfReportFlaggedByHashScanner },
    { key: Keys.SourceOfReportFlaggedByYARA, text: SourceOfReportPrePublish.SourceOfReportFlaggedByYARA },
    { key: Keys.SourceOfReportDarsyTechnicalQueueStoreOps, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueStoreOps },
    { key: Keys.SourceOfReportDarsyTechnicalQueueFAA, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueFAA },
    { key: Keys.SourceOfReportPreFlaggedByAccuracyAnalysis, text: SourceOfReportPrePublish.SourceOfReportPreFlaggedByAccuracyAnalysis },
    { key: Keys.SourceOfReportPreFlaggedByURLReputationService, text: SourceOfReportPrePublish.SourceOfReportPreFlaggedByURLReputationService },
    { key: Keys.SourceOfReportDarsyTechnicalQueueURLReputationService, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueURLReputationService },
    { key: Keys.SourceOfReportPreSuspiciousDeveloper, text: SourceOfReportPrePublish.SourceOfReportPreSuspiciousDeveloper },
    { key: Keys.SourceOfReportPreDuplicateDetection, text: SourceOfReportPrePublish.SourceOfReportPreDuplicateDetection },
    { key: Keys.SourceOfReportDarsyTechnicalQueueSuspiciousDeveloper, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueSuspiciousDeveloper },
    { key: Keys.SourceOfReportDarsyTechnicalQueueDuplicateDetection, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueDuplicateDetection },
    { key: Keys.SourceOfReportDarsyTechnicalQueueDA, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueDA },
    { key: Keys.SourceOfReportPreFlaggedByDA, text: SourceOfReportPrePublish.SourceOfReportPreFlaggedByDA },
    { key: Keys.SourceOfReportDarsyTechnicalQueueURLDetonation, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueURLDetonation },
    { key: Keys.SourceOfReportPreURLDetonation, text: SourceOfReportPrePublish.SourceOfReportPreURLDetonation },
    { key: Keys.SourceOfReportPreFlaggedbyObfuscationService, text: SourceOfReportPrePublish.SourceOfReportPreFlaggedbyObfuscationService },
    { key: Keys.SourceOfReportPreFlaggedbyPRSSYara, text: SourceOfReportPrePublish.SourceOfReportPreFlaggedbyPRSSYara },
    { key: Keys.SourceOfReportPreThreatHunting, text: SourceOfReportPrePublish.SourceOfReportPreThreatHunting },
];

export const SourceOfReportPostPublishDropdownOptions: IDropdownOption[] = [
    { key: Keys.SourceOfReportAvertServiceViolation, text: SourceOfReportPostPublish.SourceOfReportAvertServiceViolation },
    { key: Keys.SourceOfReportClientTelemetry, text: SourceOfReportPostPublish.SourceOfReportClientTelemetry },
    { key: Keys.SourceOfReportPostDuplicateDetection, text: SourceOfReportPostPublish.SourceOfReportPostDuplicateDetection },
    { key: Keys.SourceOfReportPostSuspiciousDeveloper, text: SourceOfReportPostPublish.SourceOfReportPostSuspiciousDeveloper },
    { key: Keys.SourceOfReportInactiveInChrome, text: SourceOfReportPostPublish.SourceOfReportInactiveInChrome },
    { key: Keys.SourceOfReportICMReportAbuse, text: SourceOfReportPostPublish.SourceOfReportICMReportAbuse },
    { key: Keys.SourceOfReportMalwareReported, text: SourceOfReportPostPublish.SourceOfReportMalwareReported },
    { key: Keys.SourceOfReportMediaReported, text: SourceOfReportPostPublish.SourceOfReportMediaReported },
    { key: Keys.SourceOfReportOtherReported, text: SourceOfReportPostPublish.SourceOfReportOtherReported },
    { key: Keys.SourceOfReportExtensionPolicyDownload, text: SourceOfReportPostPublish.SourceOfReportExtensionPolicyDownload },
    { key: Keys.SourceOfReportExternalReport, text: SourceOfReportPostPublish.SourceOfReportExternalReport },
    { key: Keys.SourceOfReportSearchHijackTabsUpdate, text: SourceOfReportPostPublish.SourceOfReportSearchHijackTabsUpdate },
    { key: Keys.SourceOfReportSearchHijackTabsRemove, text: SourceOfReportPostPublish.SourceOfReportSearchHijackTabsRemove },
    { key: Keys.SourceOfReportSideLoadedViaGP, text: SourceOfReportPostPublish.SourceOfReportSideLoadedViaGP },
    { key: Keys.SourceOfReportOthers, text: SourceOfReportPostPublish.SourceOfReportOthers },
    { key: Keys.SourceOfReportSuspiciousReferrerURL, text: SourceOfReportPostPublish.SourceOfReportSuspiciousReferrerURL },
    { key: Keys.SourceOfReportMaliciousSignatureCodeQL, text: SourceOfReportPostPublish.SourceOfReportMaliciousSignatureCodeQL },
    { key: Keys.SourceOfReportMaliciousSignatureWithYARA, text: SourceOfReportPostPublish.SourceOfReportMaliciousSignatureWithYARA },
    { key: Keys.SourceOfReportMaliciousSignatureWithYARAActive, text: SourceOfReportPostPublish.SourceOfReportMaliciousSignatureWithYARAActive },
    { key: Keys.SourceOfReportMaliciousSignatureWithYARAUnpublished, text: SourceOfReportPostPublish.SourceOfReportMaliciousSignatureWithYARAUnpublished },
    { key: Keys.SourceOfReportMaliciousSignatureWithYARATakedown, text: SourceOfReportPostPublish.SourceOfReportMaliciousSignatureWithYARATakedown },
    { key: Keys.SourceOfReportMaliciousSignatureDefender, text: SourceOfReportPostPublish.SourceOfReportMaliciousSignatureDefender },
    { key: Keys.SourceOfReportPostFlaggedByAccuracyAnalysis, text: SourceOfReportPostPublish.SourceOfReportPostFlaggedByAccuracyAnalysis },
    { key: Keys.SourceOfReportPostFlaggedByURLReputationService, text: SourceOfReportPostPublish.SourceOfReportPostFlaggedByURLReputationService },
    { key: Keys.SourceOfReportPostFlaggedByDA, text: SourceOfReportPostPublish.SourceOfReportPostFlaggedByDA },
    { key: Keys.SourceOfReportPostURLDetonation, text: SourceOfReportPostPublish.SourceOfReportPostURLDetonation },
    { key: Keys.SourceOfReportUkmTabsCombination, text: SourceOfReportPostPublish.SourceOfReportUkmTabsCombination },
    { key: Keys.SourceOfReportFlaggedbyUKMTelemetry, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyUKMTelemetry },
    { key: Keys.SourceOfReportFlaggedbyETFTelemetry, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyETFTelemetry },
    { key: Keys.SourceOfReportFlaggedbyETFRemoteHost, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyETFRemoteHost },
    { key: Keys.SourceOfReportFlaggedbyETFCookiesGetAll, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyETFCookiesGetAll },
    { key: Keys.SourceOfReportFlaggedbyETFCookiesGet, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyETFCookiesGet },
    { key: Keys.SourceOfReportFlaggedByHashScanner, text: SourceOfReportPrePublish.SourceOfReportFlaggedByHashScanner },
    { key: Keys.SourceOfReportFlaggedbyETFTabsExecutescript, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyETFTabsExecutescript },
    { key: Keys.SourceOfReportFlaggedbyLogAnalyticsQuery, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyLogAnalyticsQuery },
    { key: Keys.SourceOfReportPostFlaggedbyObfuscationService, text: SourceOfReportPostPublish.SourceOfReportPostFlaggedbyObfuscationService },
    { key: Keys.SourceOfReportFlaggedbyRescan, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyRescan },
    { key: Keys.SourceOfReportPostFlaggedbyPRSSYara, text: SourceOfReportPostPublish.SourceOfReportPostFlaggedbyPRSSYara },
    { key: Keys.SourceOfReportBingHijackMonitoring, text: SourceOfReportPostPublish.SourceOfReportBingHijackMonitoring },
    { key: Keys.SourceOfReportCassandraThreatIntel, text: SourceOfReportPostPublish.SourceOfReportCassandraThreatIntel },
    { key: Keys.SourceOfReportBingDSQDetections, text: SourceOfReportPostPublish.SourceOfReportBingDSQDetections },
    { key: Keys.SourceOfReportAppDefaultsDSEWebrequest, text: SourceOfReportPostPublish.SourceOfReportAppDefaultsDSEWebrequest },
    { key: Keys.SourceOfReportAppDefaultsDSEDNR, text: SourceOfReportPostPublish.SourceOfReportAppDefaultsDSEDNR },
    { key: Keys.SourceOfReportAppDefaultsTabsRemove, text: SourceOfReportPostPublish.SourceOfReportAppDefaultsTabsRemove },
    { key: Keys.SourceOfReportAppDefaultsTabsUpdate, text: SourceOfReportPostPublish.SourceOfReportAppDefaultsTabsUpdate },
    { key: Keys.SourceOfReportAppDefaultsTabsCreate, text: SourceOfReportPostPublish.SourceOfReportAppDefaultsTabsCreate },
    { key: Keys.SourceOfReportUnpublishedExtension, text: SourceOfReportPostPublish.SourceOfReportUnpublishedExtension },
    { key: Keys.SourceOfReportPostThreatHuntingFlaggedInMediaBlog, text: SourceOfReportPostPublish.SourceOfReportPostThreatHuntingFlaggedInMediaBlog },
    { key: Keys.SourceOfReportPostThreatHuntingCategoryHunting, text: SourceOfReportPostPublish.SourceOfReportPostThreatHuntingCategoryHunting },
    { key: Keys.SourceOfReportPostThreatHuntingFlaggedInVTCrxADOs, text: SourceOfReportPostPublish.SourceOfReportPostThreatHuntingFlaggedInVTCrxADOs },
    { key: Keys.SourceOfReportPostThreatHuntingNewExperiment, text: SourceOfReportPostPublish.SourceOfReportPostThreatHuntingNewExperiment },
    { key: Keys.SourceOfReportPostThreatHuntingOthers, text: SourceOfReportPostPublish.SourceOfReportPostThreatHuntingOthers },
    { key: Keys.SourceOfReportPostThreatHuntingProactive, text: SourceOfReportPostPublish.SourceOfReportPostThreatHuntingProactive },
    { key: Keys.SourceOfReportPostThreatHuntingRecentlyRevokedChrome, text: SourceOfReportPostPublish.SourceOfReportPostThreatHuntingRecentlyRevokedChrome },
    { key: Keys.SourceOfReportBingAffiliation, text: SourceOfReportPostPublish.SourceOfReportBingAffiliation },
    { key: Keys.SourceOfReportBingAutosearch, text: SourceOfReportPostPublish.SourceOfReportBingAutosearch },
    { key: Keys.SourceOfReportBingAdsHijack, text: SourceOfReportPostPublish.SourceOfReportBingAdsHijack },
    { key: Keys.SourceOfReportChromeRemoved, text: SourceOfReportPostPublish.SourceOfReportChromeRemoved },
];

export const ProductTypeDropdownOptions: IDropdownOption[] = [
    { key: Keys.ProductTypeExtension, text: ProductType.Extension },
    { key: Keys.ProductTypeTheme, text: ProductType.Theme },
];

export const ReproducibleDropdownOptions: IDropdownOption[] = [
    { key: Keys.ReproducibleYesDropDownKey, text: localeStrings[`Yes`] },
    { key: Keys.ReproducibleNoDropDownKey, text: localeStrings[`No`] },
];

export const ListenersDropdown: IDropdownOption[] = [
    { key: ScannerKeys.StaticAnalysisCodeQLScan, text: ScannerKeys.StaticAnalysisCodeQLScan },
    { key: ScannerKeys.DynamicAnalysisNetworkLogScan, text: ScannerKeys.DynamicAnalysisNetworkLogScan },
    { key: ScannerKeys.DynamicAnalysisActivityLogScan, text: ScannerKeys.DynamicAnalysisActivityLogScan },
    { key: ScannerKeys.StaticAnalysisTokenScan, text: ScannerKeys.StaticAnalysisTokenScan },
    { key: ScannerKeys.StaticAnalysisManifestScan, text: ScannerKeys.StaticAnalysisManifestScan },
    { key: ScannerKeys.StaticAnalysisDeveloperScan, text: ScannerKeys.StaticAnalysisDeveloperScan },
    { key: ScannerKeys.StaticAnalysisMetadataScan, text: ScannerKeys.StaticAnalysisMetadataScan },
    { key: ScannerKeys.StaticAnalysisDuplicateDetectionScan, text: ScannerKeys.StaticAnalysisDuplicateDetectionScan },
    { key: ScannerKeys.AggregateReport, text: ScannerKeys.AggregateReport },
    { key: ScannerKeys.StaticAnalysisObfuscationScan, text: localeStrings['ObfuscationDetector'] },
    { key: ScannerKeys.HashScan, text: localeStrings['HashScanner'] },
];

export const PluginListenersDropdown: IDropdownOption[] = [
    { key: ScannerKeys.StaticAnalysisManifestScan, text: ScannerKeys.StaticAnalysisManifestScan },
    { key: ScannerKeys.EvalScan, text: ScannerKeys.EvalScan },
    { key: ScannerKeys.EvalRAIScan, text: ScannerKeys.EvalRAIScan },
];

export const MaliciousDropdownOptions: IDropdownOption[] = [
    { key: Keys.MaliciousYesDropDownKey, text: localeStrings[`Yes`] },
    { key: Keys.MaliciousNoDropDownKey, text: localeStrings[`No`] },
];

export const AttackVectorDropdownOptions: IDropdownOption[] = [
    { key: Keys.XSSAttackVectorCategory, text: AttackVectorCategory.XSS },
    {
        key: Keys.PhishingAttackVectorCategory,
        text: AttackVectorCategory.Phishing,
    },
    {
        key: Keys.NTPHijackAttackVectorCategory,
        text: AttackVectorCategory.NTPHijack,
    },
    {
        key: Keys.SearchHijackAttackVectorCategory,
        text: AttackVectorCategory.SearchHijack,
    },
    { key: Keys.DDosAttackVectorCategory, text: AttackVectorCategory.DDos },
    {
        key: Keys.PasswordSniffingAttackVectorCategory,
        text: AttackVectorCategory.PasswordSniffing,
    },
    {
        key: Keys.TrojanImposterAttackVectorCategory,
        text: AttackVectorCategory.TrojanImposter,
    },
    {
        key: Keys.TrojanAttackVectorCategory,
        text: AttackVectorCategory.Trojan,
    },
    {
        key: Keys.ImposterAttackVectorCategory,
        text: AttackVectorCategory.Imposter,
    },
    { key: Keys.AdwareAttackVectorCategory, text: AttackVectorCategory.Adware },
    { key: Keys.AffiliationAttackVectorCategory, text: AttackVectorCategory.Affiliation },
    {
        key: Keys.UserDataBreachAttackVectorCategory,
        text: AttackVectorCategory.UserDataBreach,
    },
    {
        key: Keys.CryptoMiningAttackVectorCategory,
        text: AttackVectorCategory.CryptoMining,
    },
    {
        key: Keys.KeyboardTrackingAttackVectorCategory,
        text: AttackVectorCategory.KeyboardTracking,
    },
    {
        key: Keys.SocialMediaHijackingAttackVectorCategory,
        text: AttackVectorCategory.SocialMediaHijacking,
    },
    {
        key: Keys.InstallingUnwantedSoftwareAttackVectorCategory,
        text: AttackVectorCategory.InstallingUnwantedSoftware,
    },
    {
        key: Keys.IframeInjectionAttackVectorCategory,
        text: AttackVectorCategory.IframeInjection,
    },
    {
        key: Keys.BlacklistedUrlAttackVectorCategory,
        text: AttackVectorCategory.BlacklistedUrl,
    },
    {
        key: Keys.OtherAttackVectorCategory,
        text: AttackVectorCategory.Other,
    },
    {
        key: Keys.ObfuscatedCodeAttackVectorCategory,
        text: AttackVectorCategory.ObfuscatedCode,
    },
    {
        key: Keys.RemoteScriptInjectionAttackVectorCategory,
        text: AttackVectorCategory.RemoteScriptInjection,
    },
    {
        key: Keys.NoneAttackVectorCategory,
        text: AttackVectorCategory.None,
    },
];

export const RecommendationDropdownOptions: IDropdownOption[] = [
    { key: Keys.FailAnalysisRecommendation, text: AnalysisRecommendation.Fail },
    { key: Keys.PassAnalysisRecommendation, text: AnalysisRecommendation.Pass },
    {
        key: Keys.PassWithNotesAnalysisRecommendation,
        text: AnalysisRecommendation.PassWithNotes,
    },
    {
        key: Keys.RevokeAnalysisRecommendation,
        text: AnalysisRecommendation.Revoke,
    },
    {
        key: Keys.TakedownAnalysisRecommendation,
        text: AnalysisRecommendation.Takedown,
    },
    {
        key: Keys.HoldAnalysisRecommendation,
        text: AnalysisRecommendation.Hold,
    },
    {
        key: Keys.RevokePendingReviewAnalysisRecommendation,
        text: AnalysisRecommendation.RevokePendingReview,
    },
];

export const developerAccountTypeOptions: IDropdownOption[] = [
    { key: Keys.DeveloperAccountTypeBusiness, text: DeveloperAccountType.Business },
    { key: Keys.DeveloperAccountTypeIndividual, text: DeveloperAccountType.Individual },
];

export const HashTableIsMaliciousDropdownOptions: IDropdownOption[] = [
    { key: Keys.MaliciousYesDropDownKey, text: localeStrings[`Yes`] },
    { key: Keys.MaliciousNoDropDownKey, text: localeStrings[`No`] },
    { key: Keys.MaliciousUnknownDropDownKey, text: localeStrings[`Unknown`] },
]

export const HashTableIsObfuscatedDropdownOptions: IDropdownOption[] = [
    { key: Keys.ObfuscatedYesDropDownKey, text: localeStrings[`Yes`] },
    { key: Keys.ObfuscatedNoDropDownKey, text: localeStrings[`No`] },
    { key: Keys.ObfuscatedUnknownDropDownKey, text: localeStrings[`Unknown`] }
]

export const SearchFilterDropdownOptions: IDropdownOption[] = [
    { key: Keys.PublishClassification, text: localeStrings[`PublishType`], itemType: DropdownMenuItemType.Header },
    { key: Keys.PrePublishDropDownKey, text: PublishType.PrePublish },
    { key: Keys.PostPublishDropDownKey, text: PublishType.PostPublish },

    {
        key: Keys.MaliciousClassification,
        text: localeStrings[`MaliciousClassification`],
        itemType: DropdownMenuItemType.Header,
    },
    { key: Keys.MaliciousYesDropDownKey, text: localeStrings[`isMalicious`] },
    { key: Keys.MaliciousNoDropDownKey, text: localeStrings[`isNotMalicious`] },

    {
        key: Keys.PrePublishReportSource,
        text: localeStrings[`PrePublishClassification`],
        itemType: DropdownMenuItemType.Header,
    },
    { key: Keys.SourceOfReportContainsObfuscatedCode, text: SourceOfReportPrePublish.SourceOfReportContainsObfuscatedCode },
    { key: Keys.SourceOfReportFlaggedByStaticAnalysis, text: SourceOfReportPrePublish.SourceOfReportFlaggedByStaticAnalysis },
    { key: Keys.SourceOfReportStoreOpsRequest, text: SourceOfReportPrePublish.SourceOfReportStoreOpsRequest },
    { key: Keys.SourceOfReportDarsyTechnicalQueue, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueue },
    { key: Keys.SourceOfReportDarsyTechnicalOthers, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalOthers },
    { key: Keys.SourceOfReportDarsyTechnicalQueueSA, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueSA },
    { key: Keys.SourceOfReportDarsyTechnicalQueueYARA, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueYARA },
    { key: Keys.SourceOfReportDarsyTechnicalQueueCodeQL, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueCodeQL },
    { key: Keys.SourceOfReportFlaggedByCodeQL, text: SourceOfReportPrePublish.SourceOfReportFlaggedByCodeQL },
    { key: Keys.SourceOfReportFlaggedByYARA, text: SourceOfReportPrePublish.SourceOfReportFlaggedByYARA },
    { key: Keys.SourceOfReportDarsyTechnicalQueueStoreOps, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueStoreOps },
    { key: Keys.SourceOfReportDarsyTechnicalQueueFAA, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueFAA },
    { key: Keys.SourceOfReportPreFlaggedByAccuracyAnalysis, text: SourceOfReportPrePublish.SourceOfReportPreFlaggedByAccuracyAnalysis },
    { key: Keys.SourceOfReportPreFlaggedByURLReputationService, text: SourceOfReportPrePublish.SourceOfReportPreFlaggedByURLReputationService },
    { key: Keys.SourceOfReportDarsyTechnicalQueueURLReputationService, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueURLReputationService },
    { key: Keys.SourceOfReportPreSuspiciousDeveloper, text: SourceOfReportPrePublish.SourceOfReportPreSuspiciousDeveloper },
    { key: Keys.SourceOfReportPreDuplicateDetection, text: SourceOfReportPrePublish.SourceOfReportPreDuplicateDetection },
    { key: Keys.SourceOfReportDarsyTechnicalQueueSuspiciousDeveloper, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueSuspiciousDeveloper },
    { key: Keys.SourceOfReportDarsyTechnicalQueueDuplicateDetection, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueDuplicateDetection },
    { key: Keys.SourceOfReportDarsyTechnicalQueueDA, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueDA },
    { key: Keys.SourceOfReportPreFlaggedByDA, text: SourceOfReportPrePublish.SourceOfReportPreFlaggedByDA },
    { key: Keys.SourceOfReportDarsyTechnicalQueueURLDetonation, text: SourceOfReportPrePublish.SourceOfReportDarsyTechnicalQueueURLDetonation },
    { key: Keys.SourceOfReportPreURLDetonation, text: SourceOfReportPrePublish.SourceOfReportPreURLDetonation },
    { key: Keys.SourceOfReportPreFlaggedbyObfuscationService, text: SourceOfReportPrePublish.SourceOfReportPreFlaggedbyObfuscationService },
    { key: Keys.SourceOfReportPreFlaggedbyPRSSYara, text: SourceOfReportPrePublish.SourceOfReportPreFlaggedbyPRSSYara },
    { key: Keys.SourceOfReportPreThreatHunting, text: SourceOfReportPrePublish.SourceOfReportPreThreatHunting },
    {
        key: Keys.PostPublishReportSource,
        text: localeStrings[`PostPublishClassification`],
        itemType: DropdownMenuItemType.Header,
    },

    { key: Keys.SourceOfReportAvertServiceViolation, text: SourceOfReportPostPublish.SourceOfReportAvertServiceViolation },
    { key: Keys.SourceOfReportClientTelemetry, text: SourceOfReportPostPublish.SourceOfReportClientTelemetry },
    { key: Keys.SourceOfReportPostDuplicateDetection, text: SourceOfReportPostPublish.SourceOfReportPostDuplicateDetection },
    { key: Keys.SourceOfReportICMReportAbuse, text: SourceOfReportPostPublish.SourceOfReportICMReportAbuse },
    { key: Keys.SourceOfReportPostSuspiciousDeveloper, text: SourceOfReportPostPublish.SourceOfReportPostSuspiciousDeveloper },
    { key: Keys.SourceOfReportInactiveInChrome, text: SourceOfReportPostPublish.SourceOfReportInactiveInChrome },
    { key: Keys.SourceOfReportMalwareReported, text: SourceOfReportPostPublish.SourceOfReportMalwareReported },
    { key: Keys.SourceOfReportMediaReported, text: SourceOfReportPostPublish.SourceOfReportMediaReported },
    { key: Keys.SourceOfReportOtherReported, text: SourceOfReportPostPublish.SourceOfReportOtherReported },
    { key: Keys.SourceOfReportExtensionPolicyDownload, text: SourceOfReportPostPublish.SourceOfReportExtensionPolicyDownload },
    { key: Keys.SourceOfReportExternalReport, text: SourceOfReportPostPublish.SourceOfReportExternalReport },
    { key: Keys.SourceOfReportOthers, text: SourceOfReportPostPublish.SourceOfReportOthers },
    { key: Keys.SourceOfReportSuspiciousReferrerURL, text: SourceOfReportPostPublish.SourceOfReportSuspiciousReferrerURL },
    { key: Keys.SourceOfReportSearchHijackTabsUpdate, text: SourceOfReportPostPublish.SourceOfReportSearchHijackTabsUpdate },
    { key: Keys.SourceOfReportSearchHijackTabsRemove, text: SourceOfReportPostPublish.SourceOfReportSearchHijackTabsRemove },
    { key: Keys.SourceOfReportSideLoadedViaGP, text: SourceOfReportPostPublish.SourceOfReportSideLoadedViaGP },
    { key: Keys.SourceOfReportMaliciousSignatureCodeQL, text: SourceOfReportPostPublish.SourceOfReportMaliciousSignatureCodeQL },
    { key: Keys.SourceOfReportMaliciousSignatureWithYARA, text: SourceOfReportPostPublish.SourceOfReportMaliciousSignatureWithYARA },
    { key: Keys.SourceOfReportMaliciousSignatureWithYARAActive, text: SourceOfReportPostPublish.SourceOfReportMaliciousSignatureWithYARAActive },
    { key: Keys.SourceOfReportMaliciousSignatureWithYARAUnpublished, text: SourceOfReportPostPublish.SourceOfReportMaliciousSignatureWithYARAUnpublished },
    { key: Keys.SourceOfReportMaliciousSignatureWithYARATakedown, text: SourceOfReportPostPublish.SourceOfReportMaliciousSignatureWithYARATakedown },
    { key: Keys.SourceOfReportMaliciousSignatureDefender, text: SourceOfReportPostPublish.SourceOfReportMaliciousSignatureDefender },
    { key: Keys.SourceOfReportPostFlaggedByAccuracyAnalysis, text: SourceOfReportPostPublish.SourceOfReportPostFlaggedByAccuracyAnalysis },
    { key: Keys.SourceOfReportPostFlaggedByURLReputationService, text: SourceOfReportPostPublish.SourceOfReportPostFlaggedByURLReputationService },
    { key: Keys.SourceOfReportPostFlaggedByDA, text: SourceOfReportPostPublish.SourceOfReportPostFlaggedByDA },
    { key: Keys.SourceOfReportPostURLDetonation, text: SourceOfReportPostPublish.SourceOfReportPostURLDetonation },
    { key: Keys.SourceOfReportFlaggedbyUKMTelemetry, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyUKMTelemetry },
    { key: Keys.SourceOfReportFlaggedbyETFTelemetry, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyETFTelemetry },
    { key: Keys.SourceOfReportFlaggedbyETFRemoteHost, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyETFRemoteHost },
    { key: Keys.SourceOfReportFlaggedbyETFCookiesGetAll, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyETFCookiesGetAll },
    { key: Keys.SourceOfReportFlaggedbyETFCookiesGet, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyETFCookiesGet },
    { key: Keys.SourceOfReportFlaggedbyETFTabsExecutescript, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyETFTabsExecutescript },
    { key: Keys.SourceOfReportFlaggedbyLogAnalyticsQuery, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyLogAnalyticsQuery },
    { key: Keys.SourceOfReportPostFlaggedbyObfuscationService, text: SourceOfReportPostPublish.SourceOfReportPostFlaggedbyObfuscationService },
    { key: Keys.SourceOfReportFlaggedbyRescan, text: SourceOfReportPostPublish.SourceOfReportFlaggedbyRescan },
    { key: Keys.SourceOfReportPostFlaggedbyPRSSYara, text: SourceOfReportPostPublish.SourceOfReportPostFlaggedbyPRSSYara },
    { key: Keys.SourceOfReportBingHijackMonitoring, text: SourceOfReportPostPublish.SourceOfReportBingHijackMonitoring },
    { key: Keys.SourceOfReportCassandraThreatIntel, text: SourceOfReportPostPublish.SourceOfReportCassandraThreatIntel },
    { key: Keys.SourceOfReportBingDSQDetections, text: SourceOfReportPostPublish.SourceOfReportBingDSQDetections },
    { key: Keys.SourceOfReportAppDefaultsDSEWebrequest, text: SourceOfReportPostPublish.SourceOfReportAppDefaultsDSEWebrequest },
    { key: Keys.SourceOfReportAppDefaultsDSEDNR, text: SourceOfReportPostPublish.SourceOfReportAppDefaultsDSEDNR },
    { key: Keys.SourceOfReportAppDefaultsTabsRemove, text: SourceOfReportPostPublish.SourceOfReportAppDefaultsTabsRemove },
    { key: Keys.SourceOfReportAppDefaultsTabsUpdate, text: SourceOfReportPostPublish.SourceOfReportAppDefaultsTabsUpdate },
    { key: Keys.SourceOfReportAppDefaultsTabsCreate, text: SourceOfReportPostPublish.SourceOfReportAppDefaultsTabsCreate },
    { key: Keys.SourceOfReportUnpublishedExtension, text: SourceOfReportPostPublish.SourceOfReportUnpublishedExtension },
    { key: Keys.SourceOfReportUkmTabsCombination, text: SourceOfReportPostPublish.SourceOfReportUkmTabsCombination },
    { key: Keys.SourceOfReportPostThreatHuntingFlaggedInMediaBlog, text: SourceOfReportPostPublish.SourceOfReportPostThreatHuntingFlaggedInMediaBlog },
    { key: Keys.SourceOfReportPostThreatHuntingCategoryHunting, text: SourceOfReportPostPublish.SourceOfReportPostThreatHuntingCategoryHunting },
    { key: Keys.SourceOfReportPostThreatHuntingFlaggedInVTCrxADOs, text: SourceOfReportPostPublish.SourceOfReportPostThreatHuntingFlaggedInVTCrxADOs },
    { key: Keys.SourceOfReportPostThreatHuntingNewExperiment, text: SourceOfReportPostPublish.SourceOfReportPostThreatHuntingNewExperiment },
    { key: Keys.SourceOfReportPostThreatHuntingOthers, text: SourceOfReportPostPublish.SourceOfReportPostThreatHuntingOthers },
    { key: Keys.SourceOfReportPostThreatHuntingProactive, text: SourceOfReportPostPublish.SourceOfReportPostThreatHuntingProactive },
    { key: Keys.SourceOfReportPostThreatHuntingRecentlyRevokedChrome, text: SourceOfReportPostPublish.SourceOfReportPostThreatHuntingRecentlyRevokedChrome },
    { key: Keys.SourceOfReportBingAffiliation, text: SourceOfReportPostPublish.SourceOfReportBingAffiliation },
    { key: Keys.SourceOfReportBingAutosearch, text: SourceOfReportPostPublish.SourceOfReportBingAutosearch },
    { key: Keys.SourceOfReportBingAdsHijack, text: SourceOfReportPostPublish.SourceOfReportBingAdsHijack },
    { key: Keys.SourceOfReportChromeRemoved, text: SourceOfReportPostPublish.SourceOfReportChromeRemoved },
    {
        key: Keys.PostPublishReportSource,
        text: localeStrings[`ExtensionAnalysis_ChromeStoreUrl`],
        itemType: DropdownMenuItemType.Header,
    },
    { key: Keys.ChromeStoreURLAbsent, text: localeStrings[`ChromeStoreURLAbsent`] },
    { key: Keys.ChromeStoreURLPresent, text: localeStrings[`ChromeStoreURLPresent`] },
    {
        key: Keys.PostPublishReportSource,
        text: localeStrings[`ExtensionAnalysis_DaasLogUrl`],
        itemType: DropdownMenuItemType.Header,
    },

    { key: Keys.DaasURLAbsent, text: localeStrings[`DaasLogsUrlAbsent`] },
    { key: Keys.DaasURLPresent, text: localeStrings[`DaasLogsUrlPresent`] },
    {
        key: Keys.NoneAttackVectorCategory,
        text: localeStrings[`ClearAll`],
        itemType: DropdownMenuItemType.Header,
    },
    { key: Keys.NoneAttackVectorCategory, text: AttackVectorCategory.None },
];

export const MaxItemsInPageComboBoxOption: IComboBoxOption[] = [
    { key: Keys.Max500ItemsPerPage, text: "50" },
    { key: Keys.Max1000ItemsPerPage, text: "1000" },
    { key: Keys.Max2000ItemsPerPage, text: "2000" },
    { key: Keys.Max5000ItemsPerPage, text: "5000" },
    { key: Keys.Max10000ItemsPerPage, text: "10000" },
    { key: Keys.Max20000ItemsPerPage, text: "20000" },
];

export const SearchByOptionsForMetaData: IChoiceGroupOption[] = [
    { key: Keys.SearchByCrxId, text: localeStrings[`SearchCrxId`] },
    { key: Keys.SearchByBigId, text: localeStrings[`SearchBigId`] },
];

export const IOCTypeDropdownOptions: IDropdownOption[] = [
    { key: Keys.IOCURL, text: IOCType.Url },
    { key: Keys.IOCIP, text: IOCType.IP },
    { key: Keys.IOCFileHash, text: IOCType.FileHash },
];

export const ScanTypeDropdownOptions: IDropdownOption[] = [
    { key: Keys.PrePublishScanType, text: ScanType.PrePublishScanType },
    { key: Keys.PostPublishRescanType, text: ScanType.PostPublishRescanType },
];

export const SearchByOptionsForExtensionInformation: IChoiceGroupOption[] = [
    { key: Keys.SearchBySubmissionId, text: localeStrings[`SearchSubmissionId`] },
    { key: Keys.SearchByCrxId, text: localeStrings[`SearchCrxId`] },
];

export const PluginScanTypeDropdownOptions: IDropdownOption[] = [
    { key: Keys.PrePublishScanType, text: ScanType.PrePublishScanType },
];